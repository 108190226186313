import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query getTemplatesMenus {\n    viewer {\n      id\n      store {\n        id\n        fixedtop: menu(id: \"fixedtop\") {\n          id\n          ...handleModuleDataMenuFragment\n        }\n\n        secondtop: menu(id: \"secondtop\") {\n          id\n          ...handleModuleDataMenuFragment\n        }\n\n        sidebar: menu(id: \"sidebar\") {\n          id\n          ...handleModuleDataMenuFragment\n        }\n\n        fixedbottom: menu(id: \"fixedbottom\") {\n          id\n          ...handleModuleDataMenuFragment\n        }\n      }\n    }\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { gql } from '@apollo/client';
import { handleModuleDataMenuFragment } from '@meepshop/meep-ui/lib/menu/gqls/handleModuleData';
export var getTemplatesMenus = gql(_templateObject(), handleModuleDataMenuFragment);