import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useQuery } from '@apollo/client';
import { getTemplatesMenus } from "../gqls/useTemplatesMenus";

var useTemplatesMenus = function useTemplatesMenus(page) {
  var _useQuery = useQuery(getTemplatesMenus),
      data = _useQuery.data;

  if (!data) return null;
  return _objectSpread(_objectSpread({}, page), {}, {
    fixedtop: _objectSpread(_objectSpread({}, page.fixedtop), {}, {
      menu: data.viewer.store.fixedtop
    }),
    secondtop: _objectSpread(_objectSpread({}, page.secondtop), {}, {
      menu: data.viewer.store.secondtop
    }),
    sidebar: _objectSpread(_objectSpread({}, page.sidebar), {}, {
      menu: data.viewer.store.sidebar
    }),
    fixedbottom: _objectSpread(_objectSpread({}, page.fixedbottom), {}, {
      menu: data.viewer.store.fixedbottom
    })
  });
};

export default useTemplatesMenus;